// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../libs/ID.res.js";
import * as Rest from "../../Rest.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as SuspiciousIp from "../../../models/SuspiciousIp.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

function toString(x) {
  if (x === "Desc") {
    return "desc";
  } else {
    return "asc";
  }
}

var SortDirection = {
  toString: toString
};

var SortBy = {};

var Req = {
  SortDirection: SortDirection,
  SortBy: SortBy
};

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              currentPage: field.required("currentPage", Json_Decode$JsonCombinators.$$int),
              suspiciousIps: field.required("suspiciousIps", Json_Decode$JsonCombinators.array(SuspiciousIp.Dashboard.decoder)),
              totalPages: field.required("totalPages", Json_Decode$JsonCombinators.$$int),
              totalSuspiciousIps: field.required("totalSuspiciousIps", Json_Decode$JsonCombinators.$$int)
            };
    });

var Res = {
  decoder: decoder
};

function exec(pageNum, sortBy, query) {
  var page = "page=" + String(pageNum);
  var query$1 = Belt_Option.getWithDefault(Belt_Option.map(query, (function (x) {
              return "&query=" + encodeURIComponent(x);
            })), "");
  var variant = sortBy.NAME;
  var sortBy$1 = variant === "InvalidAttempts" ? "&sort_by=suspicious_ips.invalid_attempts&sort_direction=" + toString(sortBy.VAL) : (
      variant === "IpAddress" ? "&sort_by=suspicious_ips.ip_address&sort_direction=" + toString(sortBy.VAL) : (
          variant === "Status" ? "&sort_by=suspicious_ips.status&sort_direction=" + toString(sortBy.VAL) : "&sort_by=suspicious_ips.created_at&sort_direction=" + toString(sortBy.VAL)
        )
    );
  return Rest.$$fetch("/dashboard/suspicious_ips?" + (page + (query$1 + sortBy$1)), "Get", {
              NAME: "Json",
              VAL: decoder
            }, undefined, undefined);
}

function exec$1(id, pageNum) {
  return Rest.$$fetch("/dashboard/suspicious_ips/" + ID.toString(id) + "/suspicious_emails?page=" + String(pageNum), "Get", {
              NAME: "Json",
              VAL: SuspiciousIp.Dashboard.SuspiciousEmail.Index.decoder
            }, undefined, undefined);
}

var SuspiciousEmail = {
  exec: exec$1
};

var Dashboard = {
  Req: Req,
  Res: Res,
  exec: exec,
  SuspiciousEmail: SuspiciousEmail
};

export {
  Dashboard ,
}
/* decoder Not a pure module */
