// Generated by ReScript, PLEASE EDIT WITH CARE

import * as A from "../../../../../styleguide/components/Link/A.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DashboardNavbarCss from "../../../../../styleguide/dashboard/components/navbar/DashboardNavbarCss.res.js";
import * as Routes_SuspiciousIp from "../../../../../routes/common/Routes_SuspiciousIp.res.js";

function DashboardSuspiciousIpsNavbar(props) {
  var suspiciousIp = props.suspiciousIp;
  var __currentLink = props.currentLink;
  var currentLink = __currentLink !== undefined ? __currentLink : "Index";
  var linkCss = function (link) {
    if (link === currentLink) {
      return DashboardNavbarCss.underlinedLinkContainer;
    } else {
      return DashboardNavbarCss.linkContainer;
    }
  };
  var tmp;
  tmp = currentLink === "Index" ? DashboardNavbarCss.underlinedLinkItem : DashboardNavbarCss.normallinkItem;
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsx(A.make, {
                                  href: Routes_SuspiciousIp.Dashboard.index,
                                  className: tmp,
                                  children: "Suspicious Ips"
                                }),
                            className: linkCss("Index")
                          }),
                      suspiciousIp !== undefined ? JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(A.make, {
                                    href: Routes_SuspiciousIp.Dashboard.edit(suspiciousIp.id),
                                    className: DashboardNavbarCss.underlinedLinkItem,
                                    children: "Edit Suspicious IP"
                                  }),
                              className: linkCss("Edit")
                            }) : null
                    ],
                    className: DashboardNavbarCss.barContainer
                  }),
              className: DashboardNavbarCss.barWrapper
            });
}

var Css;

var make = DashboardSuspiciousIpsNavbar;

export {
  Css ,
  make ,
}
/* A Not a pure module */
