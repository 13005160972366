// Generated by ReScript, PLEASE EDIT WITH CARE

import * as FetchSuspiciousIpsRequest from "./requests/FetchSuspiciousIpsRequest.res.js";
import * as UpdateSuspiciousIpRequest from "./requests/UpdateSuspiciousIpRequest.res.js";

var index = FetchSuspiciousIpsRequest.Dashboard.exec;

var index$1 = FetchSuspiciousIpsRequest.Dashboard.SuspiciousEmail.exec;

var SuspiciousEmail = {
  index: index$1
};

var Dashboard = {
  index: index,
  SuspiciousEmail: SuspiciousEmail
};

var update = UpdateSuspiciousIpRequest.exec;

export {
  update ,
  Dashboard ,
}
/* FetchSuspiciousIpsRequest Not a pure module */
